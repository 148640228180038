
import ValidationMixin from "@/mixins/validation";
import { SignInTypes } from "@/store/modules/auth/auth.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const AuthX = namespace(SignInTypes.MODULE);

@Component({})
export default class ForgotPassword extends Mixins(ValidationMixin) {
  @AuthX.State(SignInTypes.FORGOT_PASSWORD_STATE)
  public forgotPassword!: DefaultState;

  @AuthX.Mutation(SignInTypes.SET_FORGOT_PASSWORD_DIALOG)
  public setForgotPassword!: (deleteCategory: boolean) => void;

  @AuthX.Action(SignInTypes.FORGOT_PASSWORD)
  public sendForgotPassword!: (email: string) => Promise<void>;

  public isValid = false;

  public email = "";

  @Ref("form") public formRef!: Validator;

  async sendForgotPasswordEmail(): Promise<void> {
    await this.formRef.validate();
    if (this.isValid) {
      this.sendForgotPassword(this.email);
    }
  }

  closeForgotPasswordDialog(): void {
    this.setForgotPassword(false);
  }
}
